/* MainContent.css */

/* Base Styles */
.app {
    background-color: black;
    color: white;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    overflow: hidden; /* Prevent scrolling */
}

.header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.top-button {
    background-color: black;
    color: white;
    border: none; /* Remove the border */
    padding: 10px 20px;
    cursor: pointer;
}

.image-section {
    width: 100%;
    margin-top: -20px; /* Adjust this value to move the image up */
}

.big-image {
    width: 100%;
    height: auto;
}

.popular-destinations {
    padding: 10px;
    margin-top: -50px; /* Adjust this value to move the section up */
    position: relative; /* Ensure the plane is positioned relative to this container */
    z-index: 1; /* Ensure the plane is behind this container */
}

.popular-destinations-header {
    display: flex;
    align-items: center;
    z-index: 2; /* Ensure the text is above the plane */
    position: relative;
}

.popular-destinations h2 {
    font-size: 24px; /* Increase font size */
    text-align: left;
    font-weight: lighter;
    margin-right: 10px; /* Add margin to the right of the text */
    white-space: nowrap; /* Prevent the text from wrapping */
    z-index: 2;
    background-color: black;
    margin-left: 0px;
}

.popular-destinations-plane {
    width: 100px;
    height: auto;
    position: absolute; /* Position it absolutely */
    top: 50%; /* Adjust to vertically center with the text */
    transform: translateY(-50%); /* Center vertically */
    left: -100px; /* Start just off-screen to the left */
    z-index: 1; /* Ensure the plane is behind the text */
    animation: fly-plane 5s linear infinite; /* 5 seconds for one full loop */
}

@keyframes fly-plane {
    0% {
        transform: translateX(-100vw) translateY(-50%);
    }
    100% {
        transform: translateX(calc(100vw + 100px)) translateY(-50%);
    }
}

.scrolling-wrapper {
    overflow: hidden; /* Hide scrollbar */
    white-space: nowrap; /* Prevent wrapping */
    width: 100%;
}

.scrolling-images {
    display: flex;
}

.destination-image {
    height: 160px; /* Set a fixed width for images */
    width: 320px;
    margin-right: 25px; /* Add gap between images */
    flex-shrink: 0;
}

.about-us {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-top: 80px;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.about-us-left, .about-us-right {
    flex: 1;
    max-width: 50%; /* Each side takes up 50% of the screen width */
    margin-bottom: 20px; /* Add margin for spacing when stacked vertically */
}

.about-us-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.about-image-left {
    width: 100%; /* Make the left image take up full width of its container */
    height: auto;
}

.about-image-right {
    width: 100%; /* Make the right image take up full width of its container */
    height: auto;
    margin-bottom: 0px;
}

.about-text {
    width: 100%;
    padding: 10px;
    border: 1px solid white; /* Add border to the text box */
    text-align: left; /* Align text to the left */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.about-text h2 {
    font-size: 24px;
    font-weight: lighter;
    margin-bottom: 10px;
}

.about-text p {
    font-size: 18px;
    line-height: 1.5;
}

.contact-us {
    padding: 10px;
    margin-top: 90px;
    text-align: center;
}

.contact-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.contact-header h2 {
    margin: 0;
    font-weight: lighter;
    margin-bottom: 5px;
}

.contact-form {
    display: inline-block;
    width: 90%; /* Make the box wider */
    max-width: 800px;
    padding: 20px;
    border: 1px solid white;
    background-color: #000000;
    box-sizing: border-box;
}

.contact-form form {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    padding: 5px;
    margin: 5px;
    gap: 15px;
}

.contact-input,
.contact-textarea {
    padding: 10px;
    border: 1px solid white;
    background-color: black;
    color: white;
    font-size: 16px;
    box-sizing: border-box;
}

.contact-input {
    width: 100%; /* Make inputs take full width on mobile */
    height: 35px;
}

.contact-textarea {
    width: 100%; /* Ensure full width */
    height: 150px; /* Set a fixed height */
    resize: none; /* Prevent resizing */
    overflow-y: auto; /* Add vertical scroll */
}

.contact-textarea::-webkit-scrollbar {
    width: 5px; /* Thinner scrollbar */
}

.contact-textarea::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar color */
}

.contact-textarea::-webkit-scrollbar-thumb:hover {
    background: #555; /* Scrollbar color on hover */
}

.contact-submit-button {
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    text-align: center;
    width: auto; /* Only take necessary space */
    margin-left: auto; /* Align button to the right */
    margin-bottom: -15px; /* Remove space below the button */
}

.contact-submit-button i {
    font-size: 20px;
    color: white;
}

.contact-divider {
    border: 0;
    height: 1px;
    background: white;
    margin: 20px 0;
}

.contact-info {
    display: flex;
    justify-content: space-around; /* Display in a row on larger screens */
    align-items: center;
    padding: 5px 0;
    gap: 10px; /* Add gap between items */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.contact-info-item {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.contact-info-item i {
    font-size: 16px;
    color: white;
}

/* Ensure contact links are white */
.contact-info-item a {
    color: white;
    text-decoration: none; /* Remove underline */
}

.contact-info-item a:hover {
    text-decoration: underline; /* Underline on hover */
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
    .top-button {
        padding: 5px 10px;
        font-size: 14px;
    }

    .popular-destinations h2 {
        font-size: 20px;
    }

    .popular-destinations-plane {
        width: 80px;
    }

    .destination-image {
        width: 200px;
        height: 100px;
        margin-right: 15px;
    }

    .about-us {
        flex-direction: column;
        align-items: center;
    }

    .about-us-left, .about-us-right {
        max-width: 100%;
    }

    .about-text h2 {
        font-size: 20px;
    }

    .about-text p {
        font-size: 16px;
    }

    .contact-header h2 {
        font-size: 18px;
    }

    .contact-input,
    .contact-textarea {
        font-size: 14px;
    }

    .contact-info-item {
        font-size: 12px;
    }

    .contact-form {
        width: 100%;
        padding: 10px;
    }

    .contact-info {
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: center;
    }
}

@media only screen and (max-width: 480px) {
    .header {
        flex-direction: column;
        align-items: center;
    }

    .top-button {
        padding: 5px 10px;
        font-size: 12px;
    }

    .popular-destinations h2 {
        font-size: 18px;
    }

    .popular-destinations-plane {
        width: 60px;
    }

    .destination-image {
        width: 150px;
        height: 80px;
        margin-right: 10px;
    }

    .about-us {
        margin-top: 40px; /* Reduce the space above the About Us section */
    }

    .about-us-left, .about-us-right {
        max-width: 100%;
    }

    .about-us-left {
        margin-top: 10px; /* Reduce the space above the left image */
    }

    .about-text h2 {
        font-size: 18px;
    }

    .about-text p {
        font-size: 14px;
    }

    .contact-us {
        margin-top: 50px; /* Reduce the space between About Us and Contact Us */
    }

    .contact-header h2 {
        font-size: 16px;
    }

    .contact-input,
    .contact-textarea {
        font-size: 12px;
    }

    .contact-info-item {
        font-size: 10px;
    }
}

@media only screen and (max-width: 320px) {
    .header {
        flex-direction: column;
        align-items: center;
    }

    .top-button {
        padding: 3px 5px;
        font-size: 8px;
    }

    .big-image {
        padding-bottom: 30px;
    }

    .popular-destinations h2 {
        font-size: 12px;
    }

    .popular-destinations-plane {
        width: 50px;
    }

    .destination-image {
        width: 120px;
        height: 60px;
        margin-right: 8px;
    }

    .about-us {
        margin-top: 10px; /* Further reduce the space above the About Us section */
    }

    .about-us-left {
        margin-top: 5px; /* Further reduce the space above the left image */
    }

    .about-text h2 {
        font-size: 14px;
    }

    .about-text p {
        font-size: 10px;
    }

    .contact-us {
        margin-top: 30px; /* Further reduce the space between About Us and Contact Us */
    }

    .contact-header h2 {
        font-size: 14px;
    }

    .contact-input,
    .contact-textarea {
        font-size: 10px;
    }

    .contact-info-item {
        font-size: 8px;
    }
}
