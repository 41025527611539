/* src/App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: black;
  color: white;
}
.App {
  text-align: center;
}
